import React, { FunctionComponent } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid2,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import PaperContainer from '../components/PaperContainer';
import { useTranslation } from 'react-i18next';
import { useBridgeDHCPLeaseQuery, useNetworkConfigQuery, useTriggerClearDHCPLeasesMutation } from '../api/hooks';
import { ReactComponent as RefreshIcon } from '../assets/refresh-icon.svg';
import { ReactComponent as DeleteIcon } from '../assets/delete-icon.svg';
import ConfirmDialog from '../views/configForms/ConfirmDialog';

export const NetworkInfoPage: FunctionComponent<{}> = (): React.ReactElement | null => {
    const { t } = useTranslation();
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
    const [confirmDialogStageTwoOpen, setConfirmDialogStageTwoOpen] = React.useState(false);
    const { isPending: networkConfigPending, data: networkConfig } = useNetworkConfigQuery();
    const { data: dhcpLeases, isPending: dhcpLeasesPending } = useBridgeDHCPLeaseQuery();
    const { mutate: clearDHCPLeases, isPending: clearDHCPLeasesPending } = useTriggerClearDHCPLeasesMutation();

    const dhcpServerEnabled = !!networkConfig?.internet_bridge.dhcp_server.enabled;
    const leases = React.useMemo(() => {
        return dhcpLeases ?? [];
    }, [dhcpLeases]);

    const body = React.useMemo(() => {
        if (!dhcpServerEnabled) {
            return <Typography align={'center'}>{t('network-info-dhcp-disabled-hint')}</Typography>;
        } else {
            return (
                <>
                    <Box sx={{ paddingBottom: 2 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                gutterBottom
                                variant="subtitle1"
                                component="div"
                                sx={{ userSelect: 'none', width: '80%' }}
                            >
                                {t('network-info-dhcp-enabled-hint')}
                            </Typography>
                            <Grid2 container spacing={1}>
                                <Grid2>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        disabled={clearDHCPLeasesPending}
                                        sx={{
                                            minWidth: 0,
                                            borderRadius: '4px',
                                            p: 1,
                                        }}
                                        onClick={() => {
                                            setConfirmDialogOpen(true);
                                        }}
                                    >
                                        <DeleteIcon height={20} width={20} />
                                    </Button>
                                </Grid2>
                                <Grid2>
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        disabled={dhcpLeasesPending}
                                        sx={{
                                            minWidth: 0,
                                            borderRadius: '4px',
                                            p: 1,
                                        }}
                                        onClick={() => {}}
                                    >
                                        <RefreshIcon height={20} width={20} />
                                    </Button>
                                </Grid2>
                            </Grid2>
                        </Stack>
                    </Box>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('ip-address')}</TableCell>
                                <TableCell>{t('vendor')}</TableCell>
                                <TableCell>{t('mac-address')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {leases.map((lease) => {
                                return (
                                    <TableRow key={lease.mac}>
                                        <TableCell>{lease.address}</TableCell>
                                        <TableCell>{lease.vendor}</TableCell>
                                        <TableCell>{lease.mac}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            );
        }
    }, [t, clearDHCPLeasesPending, dhcpLeasesPending, dhcpServerEnabled, leases]);

    if (networkConfigPending || !networkConfig) {
        return (
            <PaperContainer>
                <CircularProgress />
            </PaperContainer>
        );
    }

    return (
        <>
            <PaperContainer>{body}</PaperContainer>
            <ConfirmDialog
                open={confirmDialogOpen}
                title={t('network-info-dhcp-clear-dialog-title')}
                body={t('network-info-dhcp-clear-dialog-body')}
                onClose={() => {
                    setConfirmDialogOpen(false);
                }}
                onConfirm={() => {
                    setConfirmDialogStageTwoOpen(true);

                    clearDHCPLeases();
                }}
            />
            <Dialog open={confirmDialogStageTwoOpen} onClose={() => setConfirmDialogStageTwoOpen(false)}>
                <DialogTitle>{t('network-info-dhcp-clear-confirm-dialog-title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('network-info-dhcp-clear-confirm-dialog-body')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConfirmDialogStageTwoOpen(false);
                        }}
                    >
                        {t('confirm-dialog-okay')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
