import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
    title: string;
    body: string | React.ReactElement;

    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
}

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
    title,
    body,

    open,
    onClose,
    onConfirm,
}): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                >
                    {t('confirm-dialog-okay')}
                </Button>
                <Button
                    onClick={() => {
                        onClose();
                    }}
                >
                    {t('confirm-dialog-cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
